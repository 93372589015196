import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  type IBaseModalProps,
  blockMultipleSpaces,
} from '@gbs-monorepo-packages/common';

import { type ICreateFolderProps } from '../../../../services/folders';
import {
  ContainerWithError,
  ErrorMessage,
  Fieldset,
  FormModalCustom,
  Grid,
  Input,
  Label,
} from './styles';

interface IEditDocumentModalProps
  extends Partial<Omit<IBaseModalProps, 'children' | 'onOpenChange'>> {
  onAccept: (folder: ICreateFolderProps) => Promise<boolean>;
  onDecline: () => void;
  open: boolean;
  loading: boolean;
  typeFolder: number;
}

export const AddFolderModal = ({
  onAccept,
  onDecline,
  open,
  loading,
  zIndex,
  typeFolder,
  ...props
}: IEditDocumentModalProps): JSX.Element | null => {
  interface IError {
    name?: string;
  }
  const { companyId = '' } = useParams();
  const { user } = useAuth();
  const [error, setError] = useState<IError>({});
  const [name, setName] = useState('');

  const resetForm = useCallback(() => {
    setName('');
  }, []);

  const handleAddFolder = useCallback(async () => {
    let errors = 0;
    const errorsMessage = {
      name: '',
    };

    if (!name.trim()) {
      errorsMessage.name = 'Name is required.';
      errors++;
    }

    if (errors > 0) {
      setError(errorsMessage);
      return;
    }

    setError({});
    const clientId = companyId ?? 0;
    const aux: ICreateFolderProps = {
      name,
      typeFolder,
      clientId: String(clientId),
      userId: user?.id ?? 0,
    };

    await onAccept(aux).then((result: boolean) => {
      if (result) {
        resetForm();
      }
    });
  }, [name, companyId, typeFolder, user?.id, onAccept, resetForm]);

  const handleDecline = useCallback(() => {
    resetForm();
    onDecline?.();
  }, [onDecline]);

  return !open ? null : (
    <FormModalCustom
      loading={loading}
      biggerModal
      dataCy="modal-addFolder"
      {...props}
      open={open}
      acceptText="Save"
      declineText="Cancel"
      mainText="Create a New Folder"
      onAccept={() => {
        void handleAddFolder();
      }}
      onDecline={handleDecline}
      onOpenChange={handleDecline}
      zIndex={zIndex}
    >
      <Grid>
        <ContainerWithError>
          <Fieldset filled={!!name} isInvalid={!!error.name}>
            <Input
              data-cy="input-name"
              value={name}
              autoComplete="off"
              id="name-input"
              name="name"
              onChange={(e) => {
                if (e.target.value.length <= 25) {
                  setName(e.target.value);
                }
              }}
              required
              type="text"
              onBlur={(e) => {
                setName(blockMultipleSpaces(e.target.value));
              }}
            />
            <Label data-cy="label-name" htmlFor="name-input">
              Folder Name
            </Label>
          </Fieldset>
          <ErrorMessage data-cy={error.name}>{error.name}</ErrorMessage>
        </ContainerWithError>
      </Grid>
    </FormModalCustom>
  );
};
