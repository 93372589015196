import { FaFolder } from 'react-icons/fa';
import styled from 'styled-components';

import { Table, TableCell } from '@gbs-monorepo-packages/common';
import { scrollbar } from '@gbs-monorepo-packages/styles';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 1.875rem;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const ContentsContainer = styled.div`
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.875rem;
  margin-top: 3rem;
  overflow: auto;
  padding: 0px 1.875rem 1.875rem;
  ${scrollbar}
`;

export const TableCustom = styled(Table)`
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const TableCellCustom = styled(TableCell)`
  padding: 1rem;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
`;

export const FolderIcon = styled(FaFolder)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const SearchContainer = styled.div`
  padding-bottom: 1rem;
`;
